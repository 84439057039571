import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../global/Layout'
import ContactWrapper from '../layouts/ContactWrapper'
import SimpleSection from '../layouts/SimpleSection'
import Footer from '../layouts/Footer'
import File from '../assets/files/AutoLoadIT-Terms_and_Conditions.pdf'
import {
  ContentWrapperStyled,
  SimpleLinkStyled,
} from '../common/common'
import {
  Heading1,
  Heading2,
  Typo4,
} from '../components/Typography'

const LegalPage = ({ data }) => (
  <Layout>
    <ContactWrapper
      car={data.carThree.childImageSharp.fluid}
    />
    <SimpleSection>
      <ContentWrapperStyled>
        <Heading1 as="h1">
          Legal
        </Heading1>
        <Heading2 as="h2">
          Copyright notice
        </Heading2>
        <Typo4>
          All rights reserved. All images, data, content, code, resources and copy © Copyright Autoload IT Ltd. 2019.
        </Typo4>
        <Heading2 as="h2">
          Cookie policy
        </Heading2>
        <Typo4>
          Please see our <Link to="/cookie-policy">cookies policy document here</Link>.
        </Typo4>
        <Heading2 as="h2">
          Privacy policy
        </Heading2>
        <Typo4>
          Please see our <Link to="/privacy-policy">privacy policy document here</Link>.
        </Typo4>
        <Heading2 as="h2">
          Terms and conditions
        </Heading2>
        <Typo4>
          Please see our <SimpleLinkStyled href={File} download>terms and conditions here</SimpleLinkStyled>.
        </Typo4>
      </ContentWrapperStyled>
    </SimpleSection>
    <Footer />
  </Layout>
)

export default LegalPage

export const query = graphql`
  query {
    carThree: file(relativePath: { eq: "img-car-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
